import { useQuery } from "@tanstack/react-query";
import { Entry } from "contentful";

import { useConfiguration } from "~/contexts/configuration";
import Editorials from "~/types/editorials";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";
import evaUtils from "~/utils/eva-utils";
import productUtils from "~/utils/product-utils";

import { CarouselWrapper } from "../carousel-utils/carousel-utils";
import ProductCarouselSwiper from "./product-carousel-swiper";

type Props = {
  entry: Entry<unknown>;
};

export default function ProductCarousel({ entry }: Props) {
  assertEditorialType<Editorials.ProductCarousel>(entry, "productCarousel");
  const { eva } = useConfiguration();

  const { data } = useQuery({
    queryKey: ["productCarousel", entry.fields.products?.sys.id],
    staleTime: Infinity,
    queryFn: async () => {
      const productReferenceFields = entry.fields.products.fields;
      const sortBy = productReferenceFields.sortBy;
      const sort = productUtils.getSortFieldDescriptorByContentful(sortBy);

      if ("productIds" in productReferenceFields) {
        const response = await evaUtils.getProductsByIdsWithChildren(eva, productReferenceFields.productIds, sort);
        return response.Products?.filter((p) => productUtils.hasValidDisplayPrice(p)) ?? [];
      } else {
        const response = await evaUtils.getProductsBySetWithChildren(eva, productReferenceFields.productSetId, sort);
        return response.Products?.filter((p) => productUtils.hasValidDisplayPrice(p)) ?? [];
      }
    },
  });

  if (!isActiveEntry(entry)) return null;

  return (
    <>
      {data && data.length > 0 ? (
        <CarouselWrapper entry={entry}>
          <ProductCarouselSwiper products={data} listScope={entry.sys.id} enableLoop={entry.fields.enableLoop} />
        </CarouselWrapper>
      ) : null}
    </>
  );
}
