import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import SwiperCore, { FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import IntersectionWrapper from "~/components/common/intersection-wrapper";
import SkeletonProductTile from "~/components/common/skeletons/skeleton-product-tile";
import Constants from "~/utils/constants";
import TrackingUtils from "~/utils/tracking-utils";

import { CarouselArrows, updateArrowStatus } from "../carousel-utils/carousel-utils";
import styles from "./product-carousel-swiper.module.scss";

const ProductTile = dynamic(() => import("../../../components/products/product-tile"), {
  loading: () => <SkeletonProductTile />,
});

type Props = {
  products: any[];
  enableLoop?: boolean;
  listScope?: string;
};

export default function ProductCarouselSwiper({ products, enableLoop, listScope }: Props) {
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>();
  const [arrowsStatus, setArrowsStatus] = useState({ arrowL: true, arrowR: true });
  const [isFirstRender, setIsFirstRender] = useState(true);

  const MAX_SLIDES_DESKTOP = 5;
  const isInfiniteScroll = enableLoop && products.length >= MAX_SLIDES_DESKTOP * 2;

  useEffect(() => {
    if (swiperInstance) {
      setArrowsStatus(updateArrowStatus(swiperInstance, isInfiniteScroll));
    }
  }, [isInfiniteScroll, swiperInstance]);

  return (
    <IntersectionWrapper
      once={true}
      onIntersection={(inView: boolean) => {
        if (inView && listScope) {
          TrackingUtils.track(
            TrackingUtils.mapEcommerceEvent(
              "view_item_list",
              products.map((e, i) =>
                TrackingUtils.mapEcomItem(e, e.children?.[0], { index: i, item_list_name: listScope })
              ),
              { user_action: "impression" }
            )
          );
        }
      }}
    >
      <Swiper
        modules={[FreeMode]}
        freeMode={{ enabled: true, sticky: false }}
        onSwiper={setSwiperInstance}
        onTransitionEnd={(currentSwiper) => setArrowsStatus(updateArrowStatus(currentSwiper, isInfiniteScroll))}
        onTouchEnd={() => setIsFirstRender(false)}
        centeredSlidesBounds
        grabCursor
        speed={Constants.SWIPER_TRANSITION_DURATION}
        slidesPerView={2}
        centeredSlides={products.length > 2 && (isFirstRender || !isInfiniteScroll)}
        className={styles.swiper}
        initialSlide={1}
        threshold={10}
        loop={isInfiniteScroll}
        spaceBetween={0}
        breakpoints={{
          640: {
            slidesPerView: 3,
            centeredSlides: products.length > 3 && (isFirstRender || !isInfiniteScroll),
            freeMode: { enabled: true, sticky: false },
            spaceBetween: 12,
          },
          900: {
            slidesPerView: 4,
            centeredSlides: products.length > 4 && (isFirstRender || !isInfiniteScroll),
            freeMode: { enabled: true, sticky: true },
            spaceBetween: 12,
          },
          1280: {
            slidesPerView: MAX_SLIDES_DESKTOP,
            centeredSlides: products.length > MAX_SLIDES_DESKTOP && (isFirstRender || !isInfiniteScroll),
            freeMode: { enabled: true, sticky: true },
            spaceBetween: 12,
          },
        }}
      >
        {products.map((product, i) => (
          <SwiperSlide key={i} className={styles.slide}>
            <ProductTile product={product} idxInList={i} listScope={listScope} />
          </SwiperSlide>
        ))}
      </Swiper>
      {swiperInstance && <CarouselArrows swiperInstance={swiperInstance} arrowsStatus={arrowsStatus} />}
    </IntersectionWrapper>
  );
}
