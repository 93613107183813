import cn from "classnames";

import stylesProductTile from "../../products/product-tile.module.scss";
import styles from "./skeleton-product-tile.module.scss";

export default function SkeletonProductTile() {
  return (
    <div className={stylesProductTile.container}>
      <div className={stylesProductTile.image}>
        <div className={cn(styles.innerImage, "backgroundGradientLight")} />
      </div>
      <div className={stylesProductTile.containerTags} />
      <div className={cn(stylesProductTile.description, styles.description, "backgroundVeryLightGray")} />
      <div className={cn(stylesProductTile.name, styles.name, "backgroundLightGray")} />
      <div className={cn(stylesProductTile.ratings, styles.ratings, "backgroundVeryLightGray")} />
      <div className={cn(stylesProductTile.price, styles.price, "backgroundLightGray")} />
      <div className={stylesProductTile.colorSelector} />
      <div className={cn(stylesProductTile.button, styles.button, "backgroundGradientLight")} />
    </div>
  );
}
